export default [
  {
    prop: 'id',
    label: 'STT',
    minWidth: 80,
    type: 'id'
  },
  {
    prop: 'title',
    label: 'Tên',
    minWidth: 280,
    type: 'text',
    action: 'showDescription'
  },
  {
    prop: 'price',
    label: 'Giá',
    minWidth: 150,
    type: 'text'
  },
  {
    prop: 'sold',
    label: 'Đã bán',
    minWidth: 150,
    type: 'text'
  },

]
