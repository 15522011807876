<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-filter :rules="rules" v-on:filter-change="updateFilter"></my-filter>
        </div>
        <div class="col-sm-12">
          <div class="row m-0">
            <div class="col-sm-12 mt-2 mb-2">
              <el-table class="table-striped"
                        :data="queriedData"
                        border
                        style="width: 100%"
                        @selection-change="handleSelectionChange">
                >
                <el-table-column v-for="column in tableColumns"
                                 :key="column.label"
                                 :min-width="column.minWidth"
                                 :prop="column.prop"
                                 :label="column.label">
                  <template slot-scope="props">
                    <span v-if="column.callback">
                      <el-button type="text" @click="column.callback(props.$index, props.row)">{{props.row[column.prop]}}</el-button>
                    </span>
                    <span v-else>
                      {{props.row[column.prop]}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  :min-width="120"
                  fixed="right"
                  class-name="td-actions"
                  label="Tác vụ"
                  v-if="actions && actions.length"
                >
                  <template slot-scope="props">
                    <p-button
                      :type="action.type" size="sm" icon @click="action.callback(props.$index, props.row)"
                      v-for="(action, index) in actions"
                      :key="index"
                    >
                      <i :class="action.icon"></i>
                    </p-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="col-sm-4">
              <el-select
                class="select-default"
                v-model="pagination.perPage"
                placeholder="Per page">
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item + ' nội dung/trang'"
                  :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-4 pagination-info">
              <p class="text-entries text-center">Đang hiển thị nội dung #{{from + 1}} đến #{{to}} trên tổng số {{total}}</p>
            </div>
            <div class="col-sm-4">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.total">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="dDescriptionTitle" :visible.sync="dialogDescriptionVisible">
      <div v-html="dDescriptionContent"></div>
    </el-dialog>
    <el-dialog title="Biến thể" :visible.sync="dialogVariantVisible">
      <el-table :data="dVariantList">
        <el-table-column property="title" label="Tên" width="250"></el-table-column>
        <el-table-column property="description" label="Mô tả" width="250"></el-table-column>
        <el-table-column property="priority" label="Độ ưu tiên" width="250"></el-table-column>
        <el-table-column property="price" label="Giá"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>
<script>
  import { MessageBox, Message, Table, TableColumn, Select, Option, Dropdown, Button, DropdownMenu, DropdownItem, Dialog } from 'element-ui'
  import MyTable from 'src/components/UIComponents/Table.vue'
  import MyFilter from 'src/components/UIComponents/Filter.vue'
  import PPagination from 'src/components/UIComponents/Pagination.vue'
  import addonSchemas from './addon-schemas'
  import dtHelper from 'src/helpers/datatable'

  export default{
    components: {
      MyTable,
      MyFilter,
      ElTable: Table,
      ElTableColumn: TableColumn,
      ElSelect: Select,
      ElOption: Option,
      ElDialog: Dialog,
      ElDropdown: Dropdown,
      ElButton: Button,
      ElDropdownMenu: DropdownMenu,
      ElDropdownItem: DropdownItem,
      PPagination,
    },
    computed: {
      addons() {
        return this.$store.state.addons;
      },
      pagedData () {
        return this.tableData.slice(this.from, this.to)
      },
      tableData() {
        return this.$store.state.addons;
      },
      queriedData () {
        if (!this.searchQuery) {
          let data = dtHelper.filterByRules(this.tableData, this.filterOutput);
          this.pagination.total = this.tableData.length
          return data.slice(this.from, this.to)
        }
        let result = this.tableData
          .filter((row) => {
            let isIncluded = false
            for (let key of this.propsToSearch) {
              let rowValue = row[key].toString()
              if (rowValue.includes && rowValue.includes(this.searchQuery)) {
                isIncluded = true
              }
            }
            return isIncluded
          })
        this.pagination.total = result.length
        return result.slice(this.from, this.to)
      },
      to () {
        let highBound = this.from + this.pagination.perPage
        if (this.total < highBound) {
          highBound = this.total
        }
        return highBound
      },
      from () {
        return this.pagination.perPage * (this.pagination.currentPage - 1)
      },
      total () {
        this.pagination.total = this.tableData.length
        return this.tableData.length
      },
      checkSelect(){
        return this.multipleSelection.length;
      }
    },
    data() {
      return {
        filterOutput: [],
        filter: {},
        actions: [
          {
            type: 'primary',
            icon: 'nc-icon nc-ruler-pencil',
            callback: this.edit
          },
          {
            type: 'danger',
            icon: 'nc-icon nc-simple-remove',
            callback: this.remove
          },
        ],
        rules: dtHelper.buildRules(addonSchemas),
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        searchQuery: '',
        propsToSearch: dtHelper.buildColumDefs(addonSchemas).map(col => col.prop),
        tableColumns: dtHelper.buildColumDefs([
          {
            prop: 'id',
            label: 'stt',
            minWidth: 80,
            type: 'number'
          },
          {
            prop: 'title',
            label: 'Tiêu đề',
            minWidth: 250,
            type: 'text',
          },
          {
            prop: 'price',
            label: 'Giá',
            minWidth: 250,
            type: 'text'
          },
          {
            prop: 'sold',
            label: 'Đã bán',
            minWidth: 250,
            type: 'text'
          }
        ]),
        multipleSelection: [],
        dialogVariantVisible: false,
        dialogDescriptionVisible: false,
        dDescriptionTitle: '',
        dDescriptionContent: '',
        dVariantList: []
      }
    },
    mounted() {
      window.AP = this;
      this.$store.dispatch('fetchAddons');
      this.$store.dispatch('setPageTitle', 'Quản lý tiện ích');
      this.$store.dispatch('setCurrentActions', [{
        label: 'Tạo mới',
        type: 'primary',
        icon: '',
        callback: this.createAddon
      }])
    },
    methods: {
      updateFilter(filterOutput) {
        this.filterOutput = filterOutput;
      },
      showDescription(index, row) {
        this.dDescriptionTitle = row.title;
        this.dDescriptionContent = row.content;
        this.dialogDescriptionVisible = true
      },
      showVariant(index, row) {
        this.dVariantList = row.variant;
        this.dialogVariantVisible = true;
      },
      edit(index, row){
        this.$router.push('/addons/' + row.id);
      },
      createAddon() {
        this.$router.push('/addons/create');
      },
      remove(index, row){
        MessageBox.confirm('Bạn có chắc chắn xóa không?', 'Warning', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
          type: 'warning',
          center: true
        }).then(() => {
          this.$store.dispatch('removeAddon', row.id);
          Message({
            type: 'success',
            message: 'Đã xóa tiện ích'
          });
        }).catch(() => {
          Message({
            type: 'info',
            message: 'Hủy bỏ'
          });
        });
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      }
    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
<style lang="scss">
  .el-table .td-actions{
    button.btn {
      margin-right: 5px;
    }
  }
</style>
