var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-md-12 card p-0" }, [
        _c("div", { staticClass: "card-body row p-0" }, [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("my-filter", {
                attrs: { rules: _vm.rules },
                on: { "filter-change": _vm.updateFilter },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", { staticClass: "row m-0" }, [
              _c(
                "div",
                { staticClass: "col-sm-12 mt-2 mb-2" },
                [
                  _c(
                    "el-table",
                    {
                      staticClass: "table-striped",
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.queriedData, border: "" },
                      on: { "selection-change": _vm.handleSelectionChange },
                    },
                    [
                      _vm._v("\n              >\n              "),
                      _vm._l(_vm.tableColumns, function (column) {
                        return _c("el-table-column", {
                          key: column.label,
                          attrs: {
                            "min-width": column.minWidth,
                            prop: column.prop,
                            label: column.label,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (props) {
                                  return [
                                    column.callback
                                      ? _c(
                                          "span",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return column.callback(
                                                      props.$index,
                                                      props.row
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(props.row[column.prop])
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(props.row[column.prop]) +
                                              "\n                  "
                                          ),
                                        ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                      _vm.actions && _vm.actions.length
                        ? _c("el-table-column", {
                            attrs: {
                              "min-width": 120,
                              fixed: "right",
                              "class-name": "td-actions",
                              label: "Tác vụ",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (props) {
                                    return _vm._l(
                                      _vm.actions,
                                      function (action, index) {
                                        return _c(
                                          "p-button",
                                          {
                                            key: index,
                                            attrs: {
                                              type: action.type,
                                              size: "sm",
                                              icon: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return action.callback(
                                                  props.$index,
                                                  props.row
                                                )
                                              },
                                            },
                                          },
                                          [_c("i", { class: action.icon })]
                                        )
                                      }
                                    )
                                  },
                                },
                              ],
                              null,
                              false,
                              1806344915
                            ),
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-sm-4" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "select-default",
                      attrs: { placeholder: "Per page" },
                      model: {
                        value: _vm.pagination.perPage,
                        callback: function ($$v) {
                          _vm.$set(_vm.pagination, "perPage", $$v)
                        },
                        expression: "pagination.perPage",
                      },
                    },
                    _vm._l(_vm.pagination.perPageOptions, function (item) {
                      return _c("el-option", {
                        key: item,
                        staticClass: "select-default",
                        attrs: { label: item + " nội dung/trang", value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "col-sm-4 pagination-info" }, [
                _c("p", { staticClass: "text-entries text-center" }, [
                  _vm._v(
                    "Đang hiển thị nội dung #" +
                      _vm._s(_vm.from + 1) +
                      " đến #" +
                      _vm._s(_vm.to) +
                      " trên tổng số " +
                      _vm._s(_vm.total)
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "col-sm-4" },
                [
                  _c("p-pagination", {
                    staticClass: "pull-right",
                    attrs: {
                      "per-page": _vm.pagination.perPage,
                      total: _vm.pagination.total,
                    },
                    model: {
                      value: _vm.pagination.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.pagination, "currentPage", $$v)
                      },
                      expression: "pagination.currentPage",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dDescriptionTitle,
            visible: _vm.dialogDescriptionVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogDescriptionVisible = $event
            },
          },
        },
        [
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.dDescriptionContent) },
          }),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "Biến thể", visible: _vm.dialogVariantVisible },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVariantVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.dVariantList } },
            [
              _c("el-table-column", {
                attrs: { property: "title", label: "Tên", width: "250" },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "description",
                  label: "Mô tả",
                  width: "250",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  property: "priority",
                  label: "Độ ưu tiên",
                  width: "250",
                },
              }),
              _c("el-table-column", {
                attrs: { property: "price", label: "Giá" },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }